import GraphQlMixin from '@/mixins/graphql.mixin'
import FormattingMixin from '@/mixins/formatting.mixin'
import { mapGetters } from 'vuex'

export default {
  mixins: [GraphQlMixin, FormattingMixin],
  data() {
    return {}
  },
  methods: {
    getFieldLabel(subject, name) {
      if (subject.graphTypeName === 'Sim') {
        switch (name) {
          case 'manufacturer':
            return 'Carrier'
          case 'model':
            return 'Plan'
          default:
            break
        }
      }
      return _.get(
        _.find(subject.fields, (x) => x.name === name),
        'displayName',
        _.startCase(name)
      )
    },
    getFilterFieldType(f) {
      return _.replace(_.get(f, 'field.value.type'), /!/g, '')
    },
    getReportQuery() {
      const allFields = ['id', 'name', 'description']

      const fields = this.getFieldsGql('read', 'Report', allFields)

      return this.$gql`
        query report($id: ID!) {
          report(id: $id) {
            ${fields}
          }
        }
      `
    },
    filterToWhereExpression(root) {
      if (_.isEmpty(root) || _.isEmpty(root.filters)) {
        return []
      }
      return [this.filterGroupToWhere(root, root.connector)]
    },
    filterToWhere(filter, connector) {
      let where = {
        path: _.get(filter, 'field.value.name'),
        comparison: _.get(filter, 'operator.value'),
        value: '',
        negate: filter.negate,
        connector
      }
      if (
        typeof filter.value === 'object' ||
        typeof filter.value === 'undefined'
      ) {
        if (filter.field.value.type === 'Boolean') {
          where.path = `${filter.field.value.name}`
          where.value = filter.value.value
        } else {
          let ids = []
          let names = []
          let selections = _.castArray(filter.value)
          for (let item of selections) {
            if (_.get(item, 'id')) {
              ids.push(item.id)
            } else if (
              typeof item === 'string' ||
              item === null ||
              item === undefined
            ) {
              if (item === null && _.includes(filter.field.type, 'String')) {
                item = ''
              }
              names.push(item)
            }
          }
          if (ids.length > 0) {
            where.path = `${filter.field.value.name}.id`
            where.value = ids
          } else if (names.length > 0) {
            where.value = names
          }
        }
      } else {
        switch (filter.operator.value) {
          case 'equal':
          case 'greaterThan':
          case 'greaterThanOrEqual':
          case 'lessThan':
          case 'lessThanOrEqual':
          case 'contains':
          case 'startsWith':
          case 'endsWith':
            where.value = [filter.value]
            break
          case 'in':
            break
          case 'like':
            break
          case 'empty':
            break
          default:
            break
        }
      }
      return where
    },
    filterGroupToWhere(group, connector) {
      const where = {
        groupedExpressions: [],
        negate: group.negate,
        connector
      }
      for (let filter of group.filters) {
        if (filter.group) {
          where.groupedExpressions.push(
            this.filterGroupToWhere(filter, group.connector)
          )
        } else {
          where.groupedExpressions.push(
            this.filterToWhere(filter, group.connector)
          )
        }
      }
      return where
    }
  }
}
