export default {
  props: ['options', 'totalPage'],
  methods: {
    prevPage() {
      this.options.page === 1 ? null : this.options.page -= 1
    },
    nextPage() {
      this.options.page === this.totalPage ? null : this.options.page += 1
    }
  }
}
