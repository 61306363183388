var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.displayedHeaders,"items":_vm.reportItems,"footer-props":_vm.footerProps,"loading":_vm.$apollo.loading,"options":_vm.localOptions,"server-items-length":_vm.reportItemsCount,"multi-sort":""},on:{"update:options":function($event){_vm.localOptions=$event}},scopedSlots:_vm._u([{key:"item.applicationType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.applicationType ? 'Return Pc Accessory' : 'New Pc Accessory')+" ")]}},{key:"item.requestedDeliveryDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(item.requestedDeliveryDate))+" ")]}},{key:"item.contractEndDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(item.contractEndDate))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(item.updatedAt))+" ")]}},{key:"item.initialDeploymentDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(item.initialDeploymentDate))+" ")]}},{key:"item.operatorComputer.initialDeploymentDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(item.operatorComputer.initialDeploymentDate))+" ")]}},{key:"item.operatorMobile.initialDeploymentDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(item.operatorMobile.initialDeploymentDate))+" ")]}},{key:"item.model",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.model.name)+" ")]}},{key:"item.assignee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._.get(item.assignee, 'samAccountName', _vm._.get(item.assignee, 'name')))+" ")]}},{key:"item.operatorAssignee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._.get(item.operatorAssignee, 'samAccountName', _vm._.get(item.operatorAssignee, 'name')))+" ")]}}])}),_c('pagination',{attrs:{"options":_vm.options,"totalPage":_vm.totalPage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }